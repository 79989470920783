module.exports = {
  title: 'Strona główna', // Required
  author: 'Stowarzyszenie Inner Savages', // Required
  description: 'Strona Stowarzyszenia',
  primaryColor: '#3498db', // Required
  menuColor: '#035aa6',
  showHeaderImage: true,
  showShareButtons: false,
  postsPerPage: 5, // Required
  social: {
  },
  pathPrefix: '/',
  siteUrl: 'https://innersavages.pl/',
};
